import { BackgroundImage } from '@iheartradio/web.accomplice/background-image';
import { Group } from '@iheartradio/web.accomplice/group';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Box, Spacer } from '@iheartradio/web.companion';

import { RegistrationHeader } from '~app/components/registration-header';
import { useRedirectUrl } from '~app/hooks/use-redirect/use-redirect';

import { ForgotResetPasswordHeader } from './forgot-reset-password-header';

interface SignupLoginContainerProps {
  mode?:
    | 'sign-up'
    | 'login'
    | 'forgot-password'
    | 'reset-password'
    | 'social-login'
    | 'token-exchange';
  children?: React.ReactNode;
}

export const SignUpLoginContainer = ({
  children,
  mode = 'sign-up',
}: SignupLoginContainerProps) => {
  const { redirectUrl } = useRedirectUrl();

  const minHeight = mode === 'sign-up' ? '80rem' : '50rem';

  return (
    <Box data-test="sign-up-login-container" height="100vh">
      <Group align="center" justify="center">
        <Stack
          align="center"
          css={{
            height: '100vh',
            minHeight,
            width: {
              mobile: '100vw',
              small: '100vw',
              large: '50vw',
            },
          }}
          justify={{ mobile: 'flex-start', large: 'center' }}
        >
          <Box maxWidth="46.8rem" width="100%">
            {(
              mode === 'sign-up' ||
              mode === 'login' ||
              mode === 'social-login' ||
              mode === 'token-exchange'
            ) ?
              <RegistrationHeader mode={mode} redirectUrl={redirectUrl} />
            : <ForgotResetPasswordHeader
                mode={mode}
                redirectUrl={redirectUrl}
              />
            }
            <Spacer top={mode === 'social-login' ? '$16' : '$32'} />
            {children}
          </Box>
        </Stack>
        <BackgroundImage
          css={{
            aspectRatio: '1',
            backgroundPosition: '50% 0',
            display: {
              mobile: 'none',
              xlarge: 'block',
            },
            height: '100vh',
            minHeight,
            overflow: 'hidden',
            width: '50vw',
          }}
          data-test="bg"
          src="/sign-up-full.jpg"
        />
      </Group>
    </Box>
  );
};
